<script lang="ts">
import {inject } from 'vue'
import homeIcon from '@/assets/home.svg';
import logoutIcon from '@/assets/logout.svg'
import cursorIcon from '@/assets/cursor.svg';
import bagIcon from '@/assets/bag.svg';
import lightbulbIcon from '@/assets/lightbulb.svg'
import bookIcon from '@/assets/book.svg'
import fileIcon from '@/assets/file.svg';
import InvestidorGateway from '@/infra/gateway/investidorGateway';
import modalService from '@/services/ModalService';

import Modal from './Modal.vue';
import ModalLoginCadastro from './ModalLoginCadastro.vue';

import { redirect } from '@/services/Browser';
import { authentication } from '@/services/Authentication';




export default {
    name: "MobileMenu",
    props: {
        showMobileMenu: {type: Boolean, default: false},
        isHome: {type: Boolean, default: false},
        userAccess: {type: Object, default: undefined},
        mobileApp: {type: Boolean, default: false}
    },
    data() {
        return {
            homeIcon,
            logoutIcon,
            cursorIcon,
            bagIcon,
            lightbulbIcon,
            fileIcon,
            bookIcon,
        }
    },
    components: {
      Modal,
      ModalLoginCadastro,
    },
    emits: [
      'close'
    ],
    methods: {
        async showModal() {
            const response = await this.investidorGateway.getListaArquivos(); 

            modalService.arquivosModal(response.arquivos,response.todos_arquivos_link)
        },
        async abrirModal(tipo: string) {
            modalService.modalLoginCadastro(tipo, {investidor: this.investidorGateway})
        },
        redirect(url: string) {
          if(!this.isHome) {
            redirect(url)
          } else {
            this.$router.push(url);
          };
          this.$emit("close");
        },
        logout() {
          authentication.logout()
        }
    },
    setup() {
        return {
            investidorGateway: inject<InvestidorGateway>('investidorGateway')!,
        }
    },

}
</script>

<template>

<div 
  class="p-main"
  :class="{
    'hidden': !showMobileMenu,
    'white-bg h-[100vh]': isHome,
    'mobile-grey-bg h-[85vh]': !isHome
    }">

      <ul :class="!isHome ? 'h-1/3' : ''">
        <li @click="redirect('/')" v-if="!mobileApp" class="flex gap-7 border-top-grey py-main cursor-pointer">
          <img :src="homeIcon" class="filter-dark-blue">
          <a>Página Inicial</a>
        </li>
        <li @click="redirect('/investidor/')" class="flex gap-7 border-top-grey py-main cursor-pointer">
          <img :src="cursorIcon" class="filter-dark-blue">
          <a>Quero Investir</a>
        </li>      
        <li @click="redirect('/incorporador/')" class="flex gap-7 border-top-grey py-main cursor-pointer">
          <img :src="bagIcon" class="filter-dark-blue">
          <a>Quero Captar</a>
        </li>      
        <li v-if="!isHome" class="flex gap-7 border-top-grey py-main cursor-pointer">
          <img :src="fileIcon" class="filter-dark-blue">
            <p @click="showModal()">Meus Arquivos</p>
        </li>      
        <li @click="redirect('/ajuda/')" class="flex gap-7 border-top-grey py-main cursor-pointer">
          <img :src="lightbulbIcon" class="filter-dark-blue">
          <a href="/ajuda/">Ajuda</a>
        </li>
        <li class="flex gap-7 border-top-grey py-main cursor-pointer">
          <img :src="bookIcon" class="filter-dark-blue">
          <a href="https://urbe.me/lab/">urbe.lab</a>
        </li>
        <li @click="logout" v-if="!isHome || userAccess?.usuario" class="flex gap-7 border-top-grey py-main cursor-pointer">
          <img :src="logoutIcon" class="filter-dark-blue">
          <a>Sair</a>
        </li>         
      </ul>

      <div class="flex items-center justify-center w-full h-1/3">
        <div class="flex justify-around items-center w-full">
          <template v-if="userAccess && userAccess?.usuario">
            <div class="flex flex-col items-center mt-[8rem] gap-[5rem]">
              <a v-if="userAccess?.admin" href="/administracao/" class="cursor-pointer px-4 py-1">Admin</a>
              <a v-if="!mobileApp && userAccess?.incorporadora" href="/dashboard/incorporador/" class="cursor-pointer blue-off-bg rounded-[4rem] px-6 py-2">Painel do Incorporador</a> 
              <a href="/dashboard/investidor/" class=" cursor-pointer blue-off-bg rounded-[4rem] px-6 py-2">Painel do Investidor</a>
            </div>
          </template>
          <template v-else>
            <div v-if="isHome" class="flex gap-[4rem]">
              <button @click="abrirModal('login')">Login</button>
              <button @click="abrirModal('cadastro_investidor')" class="btn-outline-urbe">Cadastro</button>
            </div>
          </template>
        </div>
      </div>

    </div>
   
</template>

